import React from 'react';
import ToDoListComponent from './toDoList/toDoListComponent.jsx';
import GamePlay from './game/gamePlay/gamePlay.js';
import './about.css';

export default function Home() {

    return (
        <div className="homeContainer">
            <div className="homeButtonContainer">
               <div className="leftSide">
                <p className="aboutIntroCopy">Welcome. I am a certified Front-End Engineer with with extensive experience in digital content production and eCommerce management. I am skilled at creating a seamless digital user experience across desktop and mobile platforms using JavaScript, HTML and CSS based on requested designs and functionality. Through professional experience, I have become proficient in leading CMS tools including Salesforce Commerce Cloud, Contentful and Amplience. I'm known for delivering polished, quality-assured work, and bringing a keen eye for detail to technical issue resolution, QA testing, and site launch management.
                    <br/><br/>
                    With a blend of technical expertise, project management skills, and a results-driven approach, I am committed to building engaging, user-focused digital experiences.
                    </p>
                <div className="skillsInBioListContainer mt-3">
                    <div className="technicalSkills">
                <h4>Technical Skills</h4>
                <ul>
                <li><strong>Languages & Frameworks</strong>: JavaScript, React, HTML, CSS, JSON, jQuery, Bootstrap</li>
                <li><strong>CMS & eCommerce Platforms</strong>: Salesforce Commerce Cloud, Endeca, Contentful, Amplience, Wordpress, WCS</li>
                <li><strong>Marketing & Analytics</strong>: Monetate, Cordial, Salesforce Marketing Cloud</li>
                <li><strong>Collaboration Tools</strong>: Git, Confluence, Jira, Wrike</li>
                </ul>
                </div>
                <div class="careerHighlights">
                <h4>Career Highlights</h4>
                <ul>
                    <li>Creating custom landing pages and other dynamic content for multiple high-traffic eCommerce sites.</li>
                    <li>Managing technical aspects of merchandising operations, overseeing CMS implementation, page merchandising, and triaging content issues at Beachbody.</li>
                    <li>Assisting the Data and Personalization team to develop content-based A/B tests to increase personalization and engagement across <a href="https://www.torrid.com/">Torrid.com</a> using the <a href="https://monetate.com/">Monetate</a> Platform. </li>
                    </ul>
                    </div>

                    </div>
                    </div>
                    <div className="rightSide">
                    <ToDoListComponent />
                    <GamePlay />
                    </div>
            </div>
        </div>
    )

}