import React, {useState, useEffect, useRef} from 'react';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoHeartCircle, IoHeartDislikeCircleOutline } from "react-icons/io5";

function Item({ item, setTodos }) {
const [editing, setEditing] = useState(false);
const inputRef = useRef(null);

const completeTodo = () => {
  setTodos((prevTodos) => {
    const updatedTodos = prevTodos.map((todo) =>
      todo.id === item.id ? { ...todo, isCompleted: !todo.isCompleted } : todo
    );
    // Update localStorage after marking todo as completed
    localStorage.setItem("todos", JSON.stringify(updatedTodos));
    return updatedTodos;
  });
};


  const handleEdit = () => {
    setEditing(true);
  }
useEffect(() => {
  if (editing && inputRef.current) {
    inputRef.current.focus();
    inputRef.current.setSelectionRange(
      inputRef.current.value.length,
      inputRef.current.value.length
    );
  }
}, [editing]);

const handleInputSubmit = (event) => {
  event.preventDefault();
  setEditing(false);
};

const handleInputBlur = () => {
    setEditing(false);
};

const handleInputChange = (e) => {
  setTodos((prevTodos) =>
    prevTodos.map((todo) =>
      todo.id === item.id ? { ...todo, title: e.target.value } : todo
    )
  );
};

const handleDelete = () => {
  setTodos((prevTodos) => {
    const updatedTodos = prevTodos.filter((todo) => todo.id !== item.id);
    // Update localStorage after deleting todo
    localStorage.setItem("todos", JSON.stringify(updatedTodos));
    return updatedTodos;
  });
};

  return (
    <li id={item?.id} className="todoItem">
      {editing ? (
        <form className="editForm" onSubmit={handleInputSubmit}>
          <label htmlFor="editTodo">
          <input
            ref={inputRef}
            type="text"
            name="editTodo"
            id="editTodo"
            defaultValue={item?.title}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
          />
          </label>
        </form>
      ) : (
        <>
       <button className="todoItemsLeft" onClick={completeTodo}>
              {item.isCompleted ? <IoHeartCircle /> : <IoHeartDislikeCircleOutline />}
              <p>{item?.title}</p>
      </button>
      <div className="todoItemsRight">
        <button onClick={handleEdit}>
          <span className="visuallyHidden">Edit</span>
          <FaRegEdit />
        </button>
        <button onClick={handleDelete}>
          <span className="visuallyHidden">Delete</span>
          <RiDeleteBin6Line />
        </button>
        </div>
        </>
      )}
    </li>
  );
}



export default function ToDoList({ todos, setTodos }) {
  return (
    <ol className="todoList">
      {todos && todos.length > 0 ? (
        todos.map((item, index) => <Item key={index} item={item} todos={todos} setTodos={setTodos} />)
      ) : (
        <p>Seems lonely in here, what are you up to?</p>
      )}
    </ol>
  );
}