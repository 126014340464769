import React, {useState, useEffect} from "react";
import { FaGithubSquare, FaLinkedin } from "react-icons/fa";
import { IconContext } from 'react-icons';
import './footer.css';

export default function Footer() {
 // Get the current time
 const [currentTime, setCurrentTime] = useState(new Date());

 // Update the time every second
 useEffect(() => {
     const intervalId = setInterval(() => {
         setCurrentTime(new Date());
     }, 1000);

     // Cleanup interval on component unmount
     return () => clearInterval(intervalId);
 }, []);
    

 const pstTime = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Los_Angeles',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short' // Include the time zone name
}).format(currentTime);

// Format the local time
const localTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short' // Include the time zone name
}).format(currentTime);

    return (
        <footer className="footerStyle">
            <IconContext.Provider value={{ color: "black", className: "footerIcon", size: "2.5rem" }}>
                <div className="foot1">
                    <a href="https://github.com/optimissist" target="_blank" rel="noreferrer"><FaGithubSquare /></a>
                    <a href="https://www.linkedin.com/in/marlaschevker/" target="_blank" rel="noreferrer"><FaLinkedin /></a>
                </div>
            </IconContext.Provider>
            <div className="foot2 timing">My Time: {pstTime} 
                <span className="spaceForTiming">Your Time: {localTime}</span>
            </div>
        </footer>
    );
}