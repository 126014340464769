import React from "react";
import {FormFields} from "./form.js";

function FormPage() {
    return (
        <>
            <React.Fragment>
                <FormFields></FormFields>
            </React.Fragment>
        </>
    );
}
export default FormPage;