import { FaRegCheckSquare } from "react-icons/fa";

export default function ListHeader() {
    return (
      <div className="todoHeaderContainer">
        <FaRegCheckSquare />
 &nbsp;&nbsp;
        <h1>To Do</h1>
      </div>
    );
  }
