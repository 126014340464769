import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Jpg from "../../../../public/images/ScrollingSearchBar.jpg";
import Mov from "../../../../public/images/ScrollingSearchBar.mov";
import "./portfolioItems.css";


export default function PortfolioItem3() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow} className="lightBoxButton">
                <img src={Jpg} className="lightBoxButtonImg img-fluid" /><br/>Scrolling Search Bar
            </Button>

            <Modal show={show} onHide={handleClose} dialogClassName="modal90w">
                <Modal.Header closeButton>
                    <Modal.Title className="lightBoxTitle">Scrolling Search Bar</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <video src={Mov} className="lightBoxImage img-fluid" autoPlay loop/>
                    <p className="lightBoxCopy">As a part of an A/B test for Torrid.com, I developed scrolling placeholder text for the search bar. The code dynamically updates the placeholder text through the use of a function that concatenates a base string with the current text from the array, incrementng the index to rotate through the texts. The search bar was targeted using Monetate. The A/B test is ongoing. </p>
                    {/* <p className="lightBoxLinks"><a href="https://github.com/optimissist/TBB-Portfolio/tree/main/UK:France%20Shakeology%20Microsite" target="_blank" rel="noreferrer">View on GitHub</a></p> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} className="lightBoxClose">
                        Close
                    </Button>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}