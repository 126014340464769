import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Jpg from "../../../../public/images/DenimFitGuide-mini.jpg";
import Png from "../../../../public/images/DenimFitGuide.png";
import "./portfolioItems.css";


export default function PortfolioItem2() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow} className="lightBoxButton">
                <img src={Jpg} className="lightBoxButtonImg img-fluid" /><br/>Denim Fit Guide
            </Button>

            <Modal show={show} onHide={handleClose} dialogClassName="modal90w">
                <Modal.Header closeButton>
                    <Modal.Title className="lightBoxTitle">Denim Fit Guide</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={Png} className="lightBoxImage img-fluid" />
                    <p className="lightBoxCopy">During the Torrid denim relaunch in August 2024, I was asked to build a fit guide landing page. This page was a combination of images, text and video. There are short clips under the images in Our Fits. To build this, I was given a proof, assets and instructions on functionality expectations (there are many sliders in the mobile version).</p>
                    <p className="lightBoxLinks"><a href="hhttps://www.torrid.com/clothing/jeans/denim-fit-guide/" target="_blank" rel="noreferrer">View on Torrid</a></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} className="lightBoxClose">
                        Close
                    </Button>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}