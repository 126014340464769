export default function ToDoHero({ todosCompleted, totalTodos }) {
    return (
      <section className="todoheroSection">
        <div class="blueCircle">
          {todosCompleted}/{totalTodos}
        </div>
        <div>
          {todosCompleted === 0 && totalTodos === 0 ? (
          <>
            <p className="textLarge">No Tasks</p>
            <p className="textSmall">What's next?</p>
          </>
        ) :
        todosCompleted === totalTodos ? (
          <>
            <p className="textLarge">All Tasks Done</p>
            <p className="textSmall">Good Job!</p>
          </>
        ) : (
          <>
            <p className="textLarge">{todosCompleted === 1 ? 'Task' : 'Tasks'} Done</p>
            <p className="textSmall">Keep it up</p>
          </>
        )}
        </div>
      </section>
    );
  }