import React, { useState, useEffect } from 'react';
import ListHeader from './components/listHeader.jsx';
import ToDoHero from './components/toDoHero.jsx';
import ToDoForm from './components/toDoForm.jsx';
import ToDoList from './components/toDoList.jsx';
import './toDoList.css';

export default function ToDoListComponent() {
  const [todos, setTodos] = useState([ ]);

  useEffect(() => {
    const storeTodos = localStorage.getItem("todos");
    if (storeTodos) {
      setTodos(JSON.parse(storeTodos));
    }
  }, [])

//calculate the number of completed tasks and total tasks
const todosCompleted = todos.filter(todo => todo.isCompleted).length;
const totalTodos = todos.length;

    return (
        <div className="listWrapper listContainer">
          <ListHeader />
          <div className="toDoListContainer">
          <ToDoHero todosCompleted={todosCompleted} totalTodos={totalTodos} />
          <ToDoForm setTodos={setTodos} todos={todos}/>
          <ToDoList todos={todos} setTodos={setTodos}/>
          </div>
        </div> 
      );
    }