import React, { useState } from 'react';
import { FaPlus } from "react-icons/fa";

export default function ToDoForm({ setTodos }) {
  const [input, setInput] = useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
    const value = input.trim();
    if (value) {
      const newTodo = {
        title: value,
        id: self.crypto.randomUUID(),
        isCompleted: false,
      };

      setTodos((prevTodos) => {
        const updatedTodos = [...prevTodos, newTodo];
        localStorage.setItem("todos", JSON.stringify(updatedTodos));
        return updatedTodos;
      });
      setInput('');
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <label htmlFor="todo">
        <input
          type="text"
          name="todo"
          id="todo"
          placeholder="What do you need to do?"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </label>
      <button type="submit">
        <FaPlus />
      </button>
    </form>
  );
}